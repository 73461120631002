import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import AuthProvider from './AuthProvider';
import Logout from 'pages/authentication/Logout';
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const Settings = Loadable(lazy(() => import('pages/settings')));
const Transaction = Loadable(lazy(() => import('pages/transaction')));
const Invoices = Loadable(lazy(() => import('pages/invoices')));
const Notifications = Loadable(lazy(() => import('pages/notifications')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: (
    <AuthProvider>
      <MainLayout />
    </AuthProvider>
  ),
  children: [
    {
      path: 'dashboard',
      element: <DashboardDefault />
    },
    {
      path: 'transaction',
      element: <Transaction />
    },
    {
      path: 'invoices',
      element: <Invoices />
    },
    {
      path: 'settings',
      element: <Settings />
    },
    {
      path: 'help',
      element: <Settings />
    },
    {
      path: 'contact',
      element: <Settings />
    },
    {
      path: 'notifications',
      element: <Notifications />
    },
    {
      path: 'logout',
      element: <Logout />
    }
  ]
};

export default MainRoutes;
