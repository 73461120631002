import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const AuthProvider = ({ children }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default AuthProvider;
