// material-ui
import { Box, Typography, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const theme = useTheme();

  const navGroups = menuItem.items.map((item) => {
    switch (item.type) {
      case 'group':
        return item.id === 'support' ? (
          <Box key={item.id}>
            <Divider sx={{ borderColor: theme.palette.binary.lighter, width: '90%', m: 'auto', mb: 1 }} />
            <NavGroup item={item} />
          </Box>
        ) : (
          <NavGroup key={item.id} item={item} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2, flexGrow: 1 }}>{navGroups}</Box>;
};

export default Navigation;
