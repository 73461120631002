import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import AuthGuard from './AuthGuard';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const OtpVerification = Loadable(lazy(() => import('pages/authentication/OtpVerification')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <AuthGuard>
      <MinimalLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: 'otp',
      element: <OtpVerification />
    }
  ]
};

export default LoginRoutes;
