import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
// material-ui
import { ButtonBase, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

// project import
// import Logo from './Logo';
import config from 'config';
import { activeItem } from 'store/reducers/menu';
import { GoogleOutlined } from '@ant-design/icons';

// ==============================|| MAIN LOGO ||============================== //


const LogoSection = ({ sx, to }) => {
  const { defaultId } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const location = useLocation();
  const currentRoute = location.pathname;

  return (
    <ButtonBase
      disableRipple
      component={Link}
      onClick={() => dispatch(activeItem({ openItem: [defaultId] }))}
      to={!to ? config.defaultPath : to}
      sx={sx}
    >
      <img src={currentRoute === '/login' ? 'greencard-logo-color.png' : 'greencard-logo-white.png'} style={{ maxWidth: '150px' }}  alt="Logo" />

      {/* <GoogleOutlined style={{ color: 'green', fontSize: '30px' }} />
      <Typography component="span" sx={{ color: currentRoute === '/login'?'#000':'#fff', fontSize: '18px', fontWeight: '600' }}>
        reencard
      </Typography> */}
    </ButtonBase>
  );
};

LogoSection.propTypes = {
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
